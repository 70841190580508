import React from 'react'
import Layout from '../components/layout'
import RiskManagement from '../components/categories/risk-management'
import Seo from '../components/seo'

const RiskManagementPage = ({ location }) => {
  return (
    <Layout location={location}>
      <RiskManagement />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Risk Management Freelancers | Codestaff'
  const description =
    'Hire the best Risk Management freelancers at Codestaff. Get the top 1% of Risk Management professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default RiskManagementPage
