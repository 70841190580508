import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Happens If I Don’t Do A Risk Assessment?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Risk can harm both business and people. Think about the consequences of failing to control risks and harming people or your staff through your business activities – the cost of replacing staff and compensating people will be high."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Do You Need A Risk Assessment For Every Job?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes. It is a legal requirement for every self-employed person and employer to assess the health and safety risk that could arise from their work."
        }
    }
}

const RiskManagement = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Risk Management Experts.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Risk Management Experts. Top
                                    companies and start-ups choose Codestaff Risk Management Experts
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Risk Management Expert now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE RISK MANAGEMENT EXPERTS' banner='/risk-management.png' bannerAlt='risk management banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default RiskManagement